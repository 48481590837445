<template>
    <div class="container mt-5">
        <div class="row">
            <div class="col-xl-12">
                <div class="card card-default">
                    <div class="card-header">
                        Logovi
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table b-table table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Level</th>
                                        <th>Date</th>
                                        <th>Message</th>
                                    </tr>
                                    <tr style="cursor: pointer;" 
                                        v-for="log in logs" :key="log.id"
                                        @click="selectLog(log)"
                                    >
                                        <td>{{log.id}}</td>
                                        <td>{{log.level}}</td>
                                        <td>{{log.timeStamp}}</td>
                                        <td>{{log.message.slice(0, 30)}}</td>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <button class="btn btn-primary mt-5" @click="fetchLogs()">Ucitaj jos</button>
                    </div>
                </div>
                <div class="card card-default mt-3" v-if="selectedLog">
                    <div class="card-header">
                        Details
                    </div>
                    <div class="card-body">
                        {{selectedLog.message}}
                    </div>
                    <!--
                    <div class="card-body" v-if="selectedLog.exception">
                        <p>selectedLog.exception</p>
                    </div> 
                    -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import axios from 'axios';
export default {
    components: { },
    setup() {
        const logs = ref([]);
        const skip = ref(0);
        const selectedLog = ref(null);
        onMounted(() => {
            fetchLogs();
        })

        const fetchLogs = () => {
            const token = localStorage.getItem('vodotermUser') && JSON.parse(localStorage.getItem('vodotermUser')).token;
            const headers = {
                headers: { Authorization: `Bearer ${token}` }
            };
            axios.get(`${process.env.VUE_APP_API_URL}/api/Logs?skip=${skip.value}&take=10`, headers)
                .then((res) => {
                    const fetchedLogs = res.data.map(item => {
                        return {...item, timeStamp: formatDate(item.timeStamp)}
                    });

                    logs.value = [...logs.value, ...fetchedLogs];
                    skip.value += 10;
                })
                .catch(err => {
                    console.log(err);
                })
        }

        const selectLog = (log) => {
            selectedLog.value = log;
        }

        const formatDate = (utc) => {
            const date = new Date(utc);
            return date.getDate() + "/" + +(date.getMonth()+1)+"/"+date.getFullYear()+
            " "+date.getHours()+ ":" +date.getMinutes();
        }

        return {
            logs,
            fetchLogs,
            selectLog,
            selectedLog
        }
    }
}
</script>

<style scoped lang="scss">
.table-responsive {
    max-height: 60vh!important;
    overflow-y: auto;
}
.table {
    width: 100%;
    color: #656565;
}

table {
    border-collapse: collapse;
}
</style>